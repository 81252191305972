import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/LayoutDocs.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ButtonsNavigation = makeShortcode("ButtonsNavigation");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Getting started`}</h2>
    <h3>{`Installation`}</h3>
    <p>{`Install the iframe-resizer package through your favourite package manager:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install iframe-resizer --save`}</code></p>
    <p>{`or`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`yarn add iframe-resizer`}</code></p>
    <p>{`If you're not familiar with package managers you can download the zip-file from the `}<a parentName="p" {...{
        "href": "https://github.com/davidjbradshaw/iframe-resizer"
      }}>{`Github page`}</a>{` or use a CDN. Include it on your page.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`<script type="text/javascript" src="../js/iframeResizer.min.js"></script>`}</code></p>
    <hr></hr>
    <h3>{`Usage`}</h3>
    <p>{`The package provides two minified JavaScript files. The first file (iframeResizer.min.js) needs to be included on the page hosting the iFrames, that's your website where the inFlow charts will be displayed.`}</p>
    <p>{`The script can be called with via JavaScript:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`const iframes = iFrameResize( [{options}], [css selector] || [iframe] );`}</code></p>
    <p>{`The second file (iframeResizer.contentWindow.min.js) can be ignored. That's something we use on oour end to serve charts to your website.`}</p>
    <h4>{`Example code`}</h4>
    <p>{`The normal configuration is to have the iFrame resize when the browser window changes size or the content of the iFrame changes. To set this up you need to configure one of the dimensions of the iFrame to a percentage and tell the library to only update the other dimension. Normally you would set the width to 100% and have the height scale to fit the content.`}</p>
    <p>{`In the basic form embedding looks something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token style"
          }}><span parentName="span" {...{
              "className": "token language-css"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token selector"
              }}>{`iframe`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`width`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 1px`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
    `}<span parentName="span" {...{
                "className": "token property"
              }}>{`min-width`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` 100%`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{`
  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`style`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`iframe`}</span>{` 
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`myId`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{`
  `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://www.inflowchart.com/chart/examples/example`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`iframe`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token script"
          }}><span parentName="span" {...{
              "className": "token language-javascript"
            }}>{`
  `}<span parentName="span" {...{
                "className": "token function"
              }}>{`iFrameResize`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token literal-property property"
              }}>{`log`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'#myId'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`
`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`script`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Using min-width to set the width of the iFrame, works around an issue in iOS that can prevent the iFrame from sizing correctly.`}</p>
    <hr></hr>
    <ButtonsNavigation linkNext="Setup" linkNextTo="/docs/embedding-charts/setup" linkPrevious="Introduction" linkPreviousTo="/docs/embedding-charts/introduction" mdxType="ButtonsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      